import '@egjs/flicking-plugins/dist/arrow.min.css';
import '@egjs/flicking-plugins/dist/pagination.min.css';
import '@egjs/react-flicking/dist/flicking.css';
import '@netfront/ekardo-content-library/dist/esm/css/index.css';
import '@netfront/ui-library/dist/esm/css/index.css';
// import '@netfront/ui-library/dist/css/_datepicker.css';
import '@netfront/ui-library/dist/css/global.css';
import '@netfront/ui-library/dist/css/helpers.css';

import 'react-circular-progressbar/dist/styles.css';



import '../styles/cms.css';
import '../styles/pages.css';
import '../styles/globals.css';
import '../styles/theme.css';
import '../styles/utils.css';

import { useEffect } from 'react';


import { ContentPageProvider, useGoogleAnalytics } from '@netfront/ekardo-content-library';
import { Icons } from '@netfront/ui-library';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { Toaster } from 'react-hot-toast';

function App({ Component, pageProps }: AppProps) {
    const { trackPageView, trackUser } = useGoogleAnalytics();
    const { events } = useRouter();

    useEffect(() => {
        const handleRouteChange = (url: string) => {
            trackPageView(url);
            trackUser();
        };

        // When the component is mounted, subscribe to router changes
        // and log those page views
        events.on('routeChangeComplete', handleRouteChange);

        // If the component is unmounted, unsubscribe
        // from the event with the `off` method
        return () => {
            events.off('routeChangeComplete', handleRouteChange);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [events]);

    return (
        <div id="root">
            <div>
                <Toaster position="top-center" reverseOrder={false} />
            </div>
            <Icons />
            <ContentPageProvider>
                {
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <Component {...pageProps} />
                }
            </ContentPageProvider>
        </div>
    );
}

// eslint-disable-next-line import/no-default-export
export default App;
